/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* body,
html {
  height: 100vh;
} */
/* body,
html {
  height: 100%;
  margin: 0;
} */
body {
    /* height: 100%; */
    /* margin: 0; */
    /* background: url(../public/images/common.png); */
    /* -webkit-background-size: cover; */
    /* -moz-background-size: cover; */
    /* -o-background-size: cover; */
    /* background-size: cover;
    background-position: center;
    background-position: center; */
    /* height: 100vh; */
    /* overflow-x: hidden;  */


}

/* .backGround {


  background: url(../public/images/common.png) no-repeat center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 100vh;
} */

.backGround {
    background-image: url(../public/images/common.png);

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    /* position: absolute;
    width: 100%;
    height: 100vh;
    background-size: cover; */


}

.backGround1 {
    background-image: url(../public/common1.png);

    /* Full height */
    /* height: 100vh; */

    /* Center and scale the image nicely */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    position: absolute;
    background-position: 50%;
    width: 100%;
    height: 100vh;
    background-size: cover;


}

h3.character_ava {
    /* text-align: center; */
    /* font-size: px; */
    font-weight: 500;
    color: white;
}

.myChart {
    height: 80vh;
    padding: 100px;
}

.myChart3 {
    height: 90vh;
    padding: 200px;
}

.inv_head {
    font-weight: bold;
}

.progress-bar {
    border-radius: 20px 80px 20px 60px !important;
    background-image: linear-gradient(to right, #E1274E 0%, #E88B57 51%) !important;
}

.progress {
    height: 25px !important;
    border: 2px solid #e77537;
    border-radius: 20px 80px 20px 60px !important;
}

.h-60vh {
    min-height: 60vh;
}
.h-45vh {
    min-height: 45vh;
}

.fs-14 {
    font-size: 12px;
}

.filters-button {
    position: absolute;
    top: 0;
    right: 0;
}

.in-active-tab {
    background-color: lightgray !important;
    isolation: isolate !important;
    border-color: #e9ecef !important;
}